import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../routes/constants";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    return (
        <nav className="bg-white dark:bg-gray-800 px-6 py-4 shadow-md w-full">
            <div className="container mx-auto flex justify-between items-center w-full">
                <Link to={ROUTES.HOME} className="text-2xl font-bold text-gray-900 dark:text-white">Movies Tec</Link>
                <div className="flex items-center space-x-8">
                    <Link to={ROUTES.HOME} className={`text-lg ${isActive(ROUTES.HOME) ? 'text-red-500' : 'text-gray-600 dark:text-gray-300'} hover:text-gray-800 dark:hover:text-white`}>Home</Link>
                    <Link to={ROUTES.POPULAR} className={`text-lg ${isActive(ROUTES.POPULAR) ? 'text-red-500' : 'text-gray-600 dark:text-gray-300'} hover:text-gray-800 dark:hover:text-white`}>Popular</Link>
                    <Link to={ROUTES.TOPRATED} className={`text-lg ${isActive(ROUTES.TOPRATED) ? 'text-red-500' : 'text-gray-600 dark:text-gray-300'} hover:text-gray-800 dark:hover:text-white`}>Top Rated</Link>
                    <Link to={ROUTES.NOWPLAYING} className={`text-lg ${isActive(ROUTES.NOWPLAYING) ? 'text-red-500' : 'text-gray-600 dark:text-gray-300'} hover:text-gray-800 dark:hover:text-white`}>Now Playing</Link>
                    <Link to={ROUTES.MYFAVORITES} className={`text-lg ${isActive(ROUTES.MYFAVORITES) ? 'text-red-500' : 'text-gray-600 dark:text-gray-300'} hover:text-gray-800 dark:hover:text-white`}>My Favorites</Link>
                </div>
                <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="lg:hidden text-gray-600 dark:text-gray-400">
                    {isMenuOpen ? "Close" : "Menu"}
                </button>
            </div>
        </nav>
    );
};

export default Header;
