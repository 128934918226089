import { IMAGE_SOURCE } from '../../constants/moviesMock'
import { IMovieCard } from './types';
import { Pill } from '../Pill';
import genres from '../../constants/genres.json';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/constants';

const MovieCard: React.FC<IMovieCard> = ({
    title,
    genreId,
    movieId,
    voteAverage,
    posterPath,
}) => {
    const navigate = useNavigate();
    const poster = IMAGE_SOURCE + posterPath;

    const getGenre = (genreId: number): string => {
        const key = 
        
        Object.values(genres.genres).find(genre => genre.id === genreId);
        if(key){
          return key.name;
        }
        return "not classified";
    }


    const navigateMovies = (id: number, movieName: string) => {
      navigate(`${ROUTES.SHOW}${id}`, { state: {movie : movieName }});
    }
  return (
      <div className='relative float-start m-8 bg-black rounded-xl overflow-hidden w-64' 
      onClick={() => {
        navigateMovies(movieId, title)
      }}
      >
        
          <img src={poster} alt='poster' className="w-full  hover:opacity-50 hover:scale-110 transition-all duration-200" />        
          <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transparent to-transparent p-4 text-white text-sm' style={{ height: '25%', background: 'linear-gradient(to top, black, transparent)' }}>
            <Pill title={getGenre(genreId)} color="red"/>
            <p className="font-semibold">{title}</p>
            <p>{voteAverage}/10</p>
          </div>
      </div>
      
  );
}

export default MovieCard