import React, { useEffect, useState } from 'react';
import { IMAGE_SOURCE } from '../../constants/moviesMock'
import { getDetails, getRecommendations } from '../../services/';
import { useParams, useNavigate } from 'react-router-dom';
import { IMovieDetail } from '../../pages/MyFavorites/types';
import { MovieCard } from '../../components/MovieCard';

const Show: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<IMovieDetail>();
    const [shows, setShows] = useState<IMovieDetail[]>([]);
    const favorites: string = localStorage.getItem('favorites') || '';
    const [recommendations, setRecommendations] = useState<IMovieDetail[]>([]);

    const poster = IMAGE_SOURCE + show?.poster_path;
    

    const getMovieDetail = async () => {
        try {
            const res = await getDetails(String(id));
            if (res && res.data) {
                setShow(res.data);
            }
        } catch (error) {
            console.error('Error fetching movie details:', error);
        }
    };

    const toggleFavorite = () => {
        const favId = String(id);
        if (!id) return;
        const favs = JSON.parse(localStorage.getItem('favorites') || '[]');
        if (favs.includes(favId)) {
            const newFavs = favs.filter((fav: string) => fav !== favId);
            localStorage.setItem('favorites', JSON.stringify(newFavs));
            setShows(shows.filter((show) => show.id !== parseInt(favId)));
        } else {
            favs.push(favId);
            localStorage.setItem('favorites', JSON.stringify(favs));
            if (show) {
                setShows([...shows, show]);
            }
        }
    };

    const runGetFavorites = async () => {
        if (favorites.length) {
            const favoritesArray = JSON.parse(favorites);
            const newShows = await Promise.all(
                favoritesArray.map(async (favorite:string) => {
                    try {
                        const res = await getDetails(String(favorite));
                        if (res && res.data) {
                            return res.data;
                        }
                    } catch (error) {
                        console.error('Error fetching favorite movie details:', error);
                    }
                })
            );
            setShows(newShows.filter((show) => show)); // Remove undefined values
            setLoading(false);
        }
    };

    const getRecommendationsData = async () => {
        try {
            const response = await getRecommendations(String(id));
            if (response && response.data) {
                setRecommendations(response.data.results);
            }
        } catch (error) {
            console.error('Error fetching recommendations:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        getMovieDetail();
        runGetFavorites();
        getRecommendationsData();
    }, [id]);

    const isFavorite = show ? favorites.includes(String(show.id)) : false;
  
    return (
        <div className="container mx-auto px-4 my-8 max-w-7xl">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/4">
                    <img src={poster} alt="Movie Poster" className="rounded-lg shadow-lg w-full" />
                </div>
                <div className="md:w-3/4 md:pl-8">
                    <h1 className="text-4xl font-bold mb-2">{show?.title}</h1>
                    <p className="text-xl">{show?.overview}</p>
                    <div className="flex items-center my-4">
                        <span className="material-icons text-xl mr-2">star_rate</span>
                        <p className="text-xl">Rating: <span className="font-semibold">{show?.vote_average}/10</span></p>
                    </div>
                    <div className="flex items-center my-2">
                        <span className="material-icons text-xl mr-2">warning</span>
                        <p className="text-xl">Adult: {show?.adult ? 'Yes' : 'No'}</p>
                    </div>
                    <div className="my-4">
                        <span className="material-icons text-xl mr-2">category</span>
                        <span className="text-xl font-semibold">Genres:</span>
                        {show?.genres.map(genre => (
                            <span key={genre.id} className="ml-2 first:ml-0 bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">{genre.name}</span>
                        ))}
                    </div>
                    <div className="flex space-x-4 mt-4">
                        <button onClick={() => navigate(-1)} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
                            Go back
                        </button>
                        <button 
                            onClick={toggleFavorite}
                            className={`${isFavorite ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded`}
                        >
                            {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                        </button>
                    </div>
                </div>
            </div>
            <div className="recommendations-section mt-8">
                <h2 className="text-2xl font-bold mb-4">Recommendations</h2>
                <div className="flex overflow-x-auto snap-x space-x-4 p-1">
                    {recommendations.map(movie => (
                    <div key={movie.id} className="flex-none">
                        <MovieCard
                            key={movie.id}
                            title={movie.title}
                            genreId={movie.genre_ids[0]}
                            movieId={movie.id}
                            voteAverage={movie.vote_average}
                            posterPath={`${IMAGE_SOURCE}${movie.poster_path}`}
                        />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Show;
