import {ROUTES} from "./constants";

import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";

import { RouteObject, createBrowserRouter } from "react-router-dom";

import Home from "../pages/Home/Home"
import Popular from "../pages/Popular/Popular";
import TopRated from "../pages/TopRated/TopRated";
import NowPlaying from "../pages/NowPlaying/NowPlaying";
import MyFavorites from "../pages/MyFavorites/MyFavorites";

import { Show } from "../pages/Show";

const routes: RouteObject[] = [
    {
        path: ROUTES.HOME, element: <PrivateRouter />,
        children: [
            {path: ROUTES.HOME, element: <Home />},
            {path: ROUTES.POPULAR, element: <Popular />},
            {path: ROUTES.TOPRATED, element: <TopRated />},
            {path: ROUTES.NOWPLAYING, element: <NowPlaying />},
            {path: ROUTES.MYFAVORITES, element: <MyFavorites/>},
            {path: `${ROUTES.SHOW}:id`, element: <Show/>},
        ]
    },
    {
        path: 'admin', element: <PublicRouter />,
        children: [
            {path: '/admin', element: <Home />}
        ]
    }
];

export const Router = createBrowserRouter(routes);