import { IPill } from './types'
import React from 'react'

const Pill: React.FC<IPill> = ({
    title,
    color,
}) => {

  return (
    <div className="inline-flex justify-center rounded-xl px-2 py-1" style={{ backgroundColor: color }}>
      {title}
    </div>
  )
  }

export default Pill
