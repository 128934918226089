import React, { useEffect, useState } from "react";
import { getPopular } from "../../services";
import { MovieCard } from "../../components/MovieCard";
import { IMovieResponse } from "./types";

const Popular: React.FC = () => {
  const [movies, setMovies] = useState<IMovieResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getPopularMovies = async () => {
    await getPopular()
      .then((data) => {
        if (data && data.data) {
          setMovies(data.data.results);
          setIsLoading(false);
        } 
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getPopularMovies();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Popular</h1>
        {isLoading ? (
            <div>Loading...</div>
        ) : (
                  <div className="px-6 font-bold text-5xl text-black m-3" style={{ display: 'grid', placeItems: 'center', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '1rem' }}>                {movies.map((movie) => (
                    <MovieCard
                        key={movie.id}
                        title={movie.title}
                        genreId={movie.genre_ids[0]}
                        movieId={movie.id}
                        voteAverage={movie.vote_average}
                        posterPath={movie.poster_path}
                    />
                ))}
            </div>
        )}
    </div>
);
};

export default Popular;
