import React, { useEffect, useState } from 'react';
import { getPopular, getTopRated, getNowPlaying } from "../../services"; 
import { MovieCard } from "../../components/MovieCard";
import { IMovieResponse } from "../../pages/Popular/types";

const HomePage = () => {
    const [popularMovies, setPopularMovies] = useState<IMovieResponse[]>([]);
    const [topRatedMovies, setTopRatedMovies] = useState<IMovieResponse[]>([]);
    const [nowPlayingMovies, setNowPlayingMovies] = useState<IMovieResponse[]>([]);

    useEffect(() => {
        const fetchMovies = async () => {
            const popularResponse = await getPopular();
            const topRatedResponse = await getTopRated();
            const nowPlayingResponse = await getNowPlaying();
            if (popularResponse && popularResponse.data) {
                setPopularMovies(popularResponse.data.results);
            }
            if (topRatedResponse && topRatedResponse.data) {
                setTopRatedMovies(topRatedResponse.data.results);
            }
            if (nowPlayingResponse && nowPlayingResponse.data) {
                setNowPlayingMovies(nowPlayingResponse.data.results);
            }
        };
        fetchMovies();
    }, []);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Popular Movies</h1>
                <div className="flex overflow-x-auto snap-x space-x-4 p-1">
                    {popularMovies.map(movie => (
                        <div key={movie.id} className="flex-none">
                            <MovieCard
                                key={movie.id}
                                title={movie.title}
                                genreId={movie.genre_ids[0]}
                                movieId={movie.id}
                                voteAverage={movie.vote_average}
                                posterPath={movie.poster_path}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Top Rated Movies</h1>
                <div className="flex overflow-x-auto snap-x space-x-4 p-1">
                    {topRatedMovies.map(movie => (
                        <div key={movie.id} className="flex-none">
                            <MovieCard
                                key={movie.id}
                                title={movie.title}
                                genreId={movie.genre_ids[0]}
                                movieId={movie.id}
                                voteAverage={movie.vote_average}
                                posterPath={movie.poster_path}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Now Playing</h1>
                <div className="flex overflow-x-auto snap-x space-x-4 p-1">
                    {nowPlayingMovies.map(movie => (
                        <div key={movie.id} className="flex-none">
                            <MovieCard
                                key={movie.id}
                                title={movie.title}
                                genreId={movie.genre_ids[0]}
                                movieId={movie.id}
                                voteAverage={movie.vote_average}
                                posterPath={movie.poster_path}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};    

export default HomePage;