import React, { useEffect, useState } from 'react'; 
import { getDetails } from '../../services';
import { IMovieDetail } from './types';
import { MovieCard } from "../../components/MovieCard";

const MyFavorites = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [shows, setShows] = useState<IMovieDetail[]>([]);
    const favorites: string = localStorage.getItem('favorites') || '';

    const runGetFavorites = async () => {
        if(favorites.length ){ 
            const favoritesArray = JSON.parse(favorites);
            const newShows = await Promise.all(
                favoritesArray.map(async (favorite:string) => {
                    return getDetails(String(favorite))
                    .then((res) => {
                        if (res && res.data) {
                            return res.data;
                        }
                    })
                    .catch((err) =>{
                        console.log(err,"err");
                    });
                })
            )
            setShows(newShows.filter(show => show !== null))
            setLoading(false)
        }
        else{
            setLoading(false)
        }
    };

    useEffect(() => {
        setLoading(true)
        runGetFavorites();
    }, [])

    return (
        <div className="container mx-auto px-4 py-8">
            {!loading ? (
                shows.length > 0 ? (
                    <div>
                        <h1 className="text-3xl font-bold text-gray-800 mb-6">Favorites</h1>
                        <div className="px-6 font-bold text-5xl text-black m-3" style={{ display: 'grid', placeItems: 'center', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '1rem' }}>                            {shows.map((show: IMovieDetail) => (
                                <MovieCard
                                    key={show.id}
                                    movieId={show.id}
                                    title={show.title}
                                    genreId={show.genres[0].id}
                                    voteAverage={show.vote_average}
                                    posterPath={show.poster_path}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1 className="text-3xl font-bold text-gray-800 mb-6">Favorites</h1>
                        <h2 className="text-lg font-semibold text-gray-800">Oops... you don't have any favorites yet</h2>
                    </div>
                )
            ) : (
                <div className="text-lg font-semibold text-gray-800">Loading...</div>
            )}
        </div>
    );
}

export default MyFavorites;
